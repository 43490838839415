import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchCompanies } from '../slices/companySlice';
import ManageCompaniesDialog from '../pages/ManageCompaniesDialog';

const CompanyManagerButton = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => setOpenDialog(true);

  const handleClose = () => {
    setOpenDialog(false);
    dispatch(fetchCompanies());
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{ position: 'absolute', top: '20px', left: '20px' }}
      >
        Manage Companies
      </Button>
      <ManageCompaniesDialog open={openDialog} onClose={handleClose} />
    </>
  );
};

export default CompanyManagerButton;
