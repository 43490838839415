// src/slices/adminSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAdminByEmail, fetchAllAdmins, updateAdminRoleAPI } from '../services/api';

export const fetchAdminDetails = createAsyncThunk(
  'admin/fetchAdminDetails',
  async (email, { rejectWithValue }) => {
    try {
      const adminData = await fetchAdminByEmail(email);
      return adminData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAdmins = createAsyncThunk(
  'admin/fetchAdmins',
  async (_, { rejectWithValue }) => {
    try {
      const admins = await fetchAllAdmins();
      return admins;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateAdminRole = createAsyncThunk(
  'admin/updateAdminRole',
  async ({ adminId, isSuperAdmin }, { rejectWithValue }) => {
    try {
      const updatedAdmin = await updateAdminRoleAPI(adminId, isSuperAdmin);
      return updatedAdmin;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    currentAdmin: null,
    admins: [],
    isSuperAdmin: false,
    loading: false,
    error: null,
  },
  reducers: {
    setIsSuperAdmin: (state, action) => {
      state.isSuperAdmin = action.payload;
    },
    resetAdminState: (state) => {
      state.currentAdmin = null;
      state.admins = [];
      state.isSuperAdmin = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAdminDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentAdmin = action.payload;
        state.isSuperAdmin = action.payload?.isSuperAdmin || false;
      })
      .addCase(fetchAdminDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAdmins.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = action.payload;
      })
      .addCase(fetchAdmins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAdminRole.fulfilled, (state, action) => {
        const index = state.admins.findIndex(admin => admin.id === action.payload.id);
        if (index !== -1) {
          state.admins[index] = action.payload;
        }
      });
  },
});

export const { setIsSuperAdmin, resetAdminState } = adminSlice.actions;
export default adminSlice.reducer;