// src/selectors.js
// import { createSelector } from '@reduxjs/toolkit';

//AUTH/USER
export const selectAdminData = state => state.auth.currentAdmin;
export const selectIsSuperAdmin = state => state.admin.isSuperAdmin;

//COMPANY
export const selectSearchTerm = state => state.company.searchTerm;
export const selectHasError = state => state.company.hasError;
export const selectErrorMessage = state => state.company.errorMessage;
export const selectCurrentAdmin = state => state.company.currentAdmin;

// ADMIN
export const selectAdmins = state => state.admin.admins;
export const selectAdminLoading = state => state.admin.loading;

export const selectCompaniesWithUserCounts = (state) => {
  console.log('[Selector] Companies:', state.company.companies);
  return state.company.companies || [];
};