import { firestore } from './authfirebase';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc
} from 'firebase/firestore';
import axios from 'axios';


export const fetchAdminByEmail = async (email) => {
  try {
    const adminsRef = collection(firestore, 'admins');
    const q = query(adminsRef, where('email', '==', email.toLowerCase()));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const adminDoc = querySnapshot.docs[0].data();
      const adminId = querySnapshot.docs[0].id;

      // Include isSuperAdmin field
      const isSuperAdmin = adminDoc.isSuperAdmin || false;

      // Fetch company details for each companyId
      const companiesRef = collection(firestore, 'companies');
      const companyPromises = adminDoc.insuranceCompanyIds.map(async (companyId) => {
        const companyQuery = query(companiesRef, where('companyId', '==', companyId));
        const companyQuerySnapshot = await getDocs(companyQuery);
        if (!companyQuerySnapshot.empty) {
          const companyData = companyQuerySnapshot.docs[0].data();

          // Fetch employee counts
          const employeesRef = collection(firestore, 'employees');
          const activeEmployeesQuery = query(employeesRef,
            where('companyId', '==', companyId),
            where('isActive', '==', true)
          );
          const disabledEmployeesQuery = query(employeesRef,
            where('companyId', '==', companyId),
            where('isActive', '==', false)
          );

          const [activeSnapshot, disabledSnapshot] = await Promise.all([
            getDocs(activeEmployeesQuery),
            getDocs(disabledEmployeesQuery)
          ]);

          return {
            ...companyData,
            activeUsers: activeSnapshot.size,
            disabledUsers: disabledSnapshot.size
          };
        }
        return null;
      });
      const companies = await Promise.all(companyPromises);

      // Filter out any null values in case some companies weren't found
      adminDoc.companies = companies.filter(company => company !== null);

      return { ...adminDoc, id: adminId, isSuperAdmin };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching admin by email:', error);
    throw error;
  }
};

export const fetchAllAdmins = async () => {
  try {
    const adminsRef = collection(firestore, 'admins');
    const querySnapshot = await getDocs(adminsRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching all admins:', error);
    throw error;
  }
};

export const updateAdminRoleAPI = async (adminId, isSuperAdmin) => {
  try {
    const adminRef = doc(firestore, 'admins', adminId);
    await updateDoc(adminRef, { isSuperAdmin });
    const updatedDoc = await adminRef.get();
    return { id: adminId, ...updatedDoc.data() };
  } catch (error) {
    console.error('Error updating admin role:', error);
    throw error;
  }
};


export const fetchCompaniesFromApi = async () => {
  const companiesRef = collection(firestore, 'companies');
  const querySnapshot = await getDocs(companiesRef);
  return querySnapshot.docs.map(doc => ({ companyId: doc.id, ...doc.data() }));
};

// Add a new company to Firestore
export const addCompanyToFirestore = async (companyData) => {
  const docRef = await addDoc(collection(firestore, 'companies'), companyData);
  const docSnap = await getDoc(docRef);
  return { companyId: docRef.id, ...docSnap.data() };
};

export const updateCompanyInFirestore = async (companyId, companyData) => {
  try {
    // Step 1: Query Firestore to find the document by companyId
    const companiesRef = collection(firestore, 'companies');
    const q = query(companiesRef, where('companyId', '==', companyId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      throw new Error(`No company found with companyId: ${companyId}`);
    }
    // Step 2: Update the first matching document (assuming companyId is unique)
    const docRef = querySnapshot.docs[0].ref;
    await updateDoc(docRef, companyData);
    // Step 3: Return the updated data
    return { companyId, ...companyData };
  } catch (error) {
    console.error('Error updating company in Firestore:', error);
    throw error;
  }
};

export const fetchEmployeesFromCompany = async (companyId) => {
  try {
    const employeesRef = collection(firestore, 'employees');
    const employeesQuery = query(employeesRef, where('companyId', '==', parseInt(companyId)));
    const employeeQuerySnapshot = await getDocs(employeesQuery);

    const companiesRef = collection(firestore, 'companies');
    const companyQuery = query(companiesRef, where('companyId', '==', parseInt(companyId)));
    const companyQuerySnapshot = await getDocs(companyQuery);

    const plansRef = collection(firestore, 'plans');

    let companyName = '';
    if (!companyQuerySnapshot.empty) {
      companyName = companyQuerySnapshot.docs[0].data().name;
    }

    const employees = await Promise.all(employeeQuerySnapshot.docs.map(async (doc) => {
      const employeeData = doc.data();
      const documentId = doc.id; // Get the document ID

      // Fetch plan name based on planId
      let planName = '';
      if (employeeData.planId) {
        const planQuery = query(plansRef, where('planId', '==', employeeData.planId));
        const planQuerySnapshot = await getDocs(planQuery);
        if (!planQuerySnapshot.empty) {
          planName = planQuerySnapshot.docs[0].data().name;
        }
      }

      return { ...employeeData, documentId, companyName, planName }; // Include documentId in the return
    }));

    return employees;
  } catch (error) {
    console.error('Error fetching employees:', error);
    return null;
  }
};



export const addEmployeeToCompany = async (employeeData) => {
  try {
    if (employeeData.email) {
      employeeData.email = employeeData.email.toLowerCase();
    }
    const docRef = await addDoc(collection(firestore, 'employees'), { ...employeeData });
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const employee = docSnap.data();
      let planName = '';

      if (employee.planId) {
        const plansRef = collection(firestore, 'plans');
        const planQuery = query(plansRef, where('planId', '==', employee.planId));
        const planQuerySnapshot = await getDocs(planQuery);
        if (!planQuerySnapshot.empty) {
          planName = planQuerySnapshot.docs[0].data().name;
        }
      }

      return { documentId: docRef.id, ...employee, planName };
    } else {
      throw new Error('Failed to fetch newly added employee.');
    }
  } catch (error) {
    console.error('Error adding employee to company:', error);
    throw error;
  }
};

export const updateEmployeeDetails = async (documentId, employeeData) => {
  try {
    if (employeeData.email) {
      employeeData.email = employeeData.email.toLowerCase();
    }

    if (!documentId) {
      throw new Error('documentId is undefined or invalid');
    }

    const employeeRef = doc(firestore, 'employees', documentId);
    await updateDoc(employeeRef, employeeData);

    const updatedDocSnap = await getDoc(employeeRef);
    if (updatedDocSnap.exists()) {
      const updatedEmployee = updatedDocSnap.data();
      let planName = '';

      if (updatedEmployee.planId) {
        const plansRef = collection(firestore, 'plans');
        const planQuery = query(plansRef, where('planId', '==', updatedEmployee.planId));
        const planQuerySnapshot = await getDocs(planQuery);
        if (!planQuerySnapshot.empty) {
          planName = planQuerySnapshot.docs[0].data().name;
        }
      }

      return { documentId, ...updatedEmployee, planName };
    } else {
      throw new Error('Failed to fetch updated employee.');
    }
  } catch (error) {
    console.error('Error updating employee details:', error);
    throw error;
  }
};

export const toggleEmployeeStatus = async (employeeId) => {
  try {
    const employeeRef = doc(firestore, 'employees', employeeId);
    const docSnap = await getDoc(employeeRef);

    if (docSnap.exists()) {
      const newStatus = !docSnap.data().isActive;
      await updateDoc(employeeRef, { isActive: newStatus });

      const updatedDocSnap = await getDoc(employeeRef);
      if (updatedDocSnap.exists()) {
        const updatedEmployee = updatedDocSnap.data();
        let planName = '';

        if (updatedEmployee.planId) {
          const plansRef = collection(firestore, 'plans');
          const planQuery = query(plansRef, where('planId', '==', updatedEmployee.planId));
          const planQuerySnapshot = await getDocs(planQuery);
          if (!planQuerySnapshot.empty) {
            planName = planQuerySnapshot.docs[0].data().name;
          }
        }

        return { documentId: updatedDocSnap.id, ...updatedEmployee, planName };
      } else {
        throw new Error('Failed to fetch updated document.');
      }
    } else {
      throw new Error('Document does not exist');
    }
  } catch (error) {
    console.error('Error in toggleEmployeeStatus:', error);
    throw error;
  }
};

export const fetchPlans = async () => {
  const plansRef = collection(firestore, 'plans');
  const querySnapshot = await getDocs(plansRef);
  return querySnapshot.docs.map(doc => doc.data());
};

export const uploadCSVData = async (companyId, csvData, token) => {
  try {
    console.log(csvData, 'csvData');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
    const url = `${process.env.REACT_APP_API_URL}/bulk-upload/${companyId}`;
    const response = await axios.post(url, csvData, { headers });
    return {
      status: 'success',
      data: response.data,
    };
  } catch (error) {
    throw new Error(error.response?.data || 'An error occurred while uploading CSV data.');
  }
};

export const analyzeMultiCompanyCSV = async ({ parsedData, newCompanies }, token) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
    const url = `${process.env.REACT_APP_API_URL}/multi-company-bulk-upload-analysis`;

    console.log('[API] Sending request to', url, {
      parsedDataLength: parsedData.length,
      newCompaniesCount: newCompanies.length
    });

    const response = await axios.post(url, { parsedData, newCompanies }, { headers });

    console.log('[API] Received response from analyzeMultiCompanyCSV', {
      status: response.status,
      dataLength: JSON.stringify(response.data).length
    });

    return response.data;
  } catch (error) {
    console.error('[API] Error in analyzeMultiCompanyCSV:', error.response?.data || error.message);
    throw new Error(error.response?.data || 'An error occurred while analyzing CSV data.');
  }
};

export const finalizeMultiCompanyUpload = async (csvData, token) => {
  console.log('[API] Starting finalizeMultiCompanyUpload', {
    changesByCompanyCount: Object.keys(csvData.changesByCompany).length,
    newCompaniesCount: csvData.newCompanies.length
  });
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
    const url = `${process.env.REACT_APP_API_URL}/finalize-multi-company-upload`;
    console.log('[API] Sending request to', url);
    const response = await axios.post(url, csvData, { headers });
    console.log('[API] Received response from finalizeMultiCompanyUpload', {
      status: response.status,
      data: response.data
    });
    return response.data;
  } catch (error) {
    console.error('[API] Error in finalizeMultiCompanyUpload:', error);
    throw new Error(error.response?.data || 'An error occurred while finalizing the upload.');
  }
};

export const approveEmployeeChanges = async (approvedData, companyId, token) => {
  console.log('[API] Starting approveEmployeeChanges', { companyId });
  try {
    const url = `${process.env.REACT_APP_API_URL}/approve-employee-changes/${companyId}`;
    console.log('[API] Sending request to', url);
    const response = await axios.post(url, approvedData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.status === 200) {
      console.log('[API] approveEmployeeChanges successful', {
        status: response.status,
        data: response.data
      });
      return {
        status: 'success',
        data: response.data
      };
    } else {
      throw new Error('Failed to process the approved changes');
    }
  } catch (error) {
    console.error('[API] Error in approveEmployeeChanges:', error);
    throw error;
  }
};