// src/utils/AuthProvider.js
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { firebaseAuth } from '../services/authfirebase';
import { signInWithEmailAndPassword, signOut as firebaseSignOut, sendPasswordResetEmail } from 'firebase/auth';
import { fetchAdminByEmail } from '../services/api';
import { setIsSuperAdmin, resetAdminState, fetchAdminDetails } from '../slices/adminSlice';

const auth = firebaseAuth;
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initializationCompleted, setInitializationCompleted] = useState(false);
  const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = async (email, password) => {
    try {
      setLoading(true);
      setError(null);
      const result = await signInWithEmailAndPassword(auth, email, password);
      setCurrentUser(result.user);
      const adminDetails = await fetchAdminByEmail(email);
      setCurrentAdmin(adminDetails);
      dispatch(setIsSuperAdmin(adminDetails?.isSuperAdmin || false));
      dispatch(fetchAdminDetails(email));
    } catch (error) {
      setError('Invalid Email or Password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const getIdToken = async () => {
    if (!currentUser) {
      return null;
    }
    try {
      return await currentUser.getIdToken(true);
    } catch (error) {
      setError(error.message);
      return null;
    }
  };

  const signOut = async () => {
    setLoading(true);
    try {
      await firebaseSignOut(auth);
      setCurrentUser(null);
      setCurrentAdmin(null);
      dispatch(resetAdminState());
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (email) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, email);
      setError(null);
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        try {
          const adminDetails = await fetchAdminByEmail(user.email);
          setCurrentAdmin(adminDetails);
          dispatch(setIsSuperAdmin(adminDetails?.isSuperAdmin || false));
          dispatch(fetchAdminDetails(user.email));
        } catch (error) {
          setError(error.message);
        }
      } else {
        setCurrentAdmin(null);
        dispatch(resetAdminState());
      }
      setLoading(false);
      setInitializationCompleted(true);
    });

    return () => unsubscribe();
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{
      currentUser,
      currentAdmin,
      error,
      loading,
      initializationCompleted,
      signInWithEmailAndPassword: signInWithEmailAndPasswordHandler,
      signOut,
      getIdToken,
      resetPassword,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;