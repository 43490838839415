import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { fetchAdmins, updateAdminRole } from '../slices/adminSlice';
import { selectAdmins, selectAdminLoading } from '../selectors';

const SuperAdminPanel = () => {
  const dispatch = useDispatch();
  const admins = useSelector(selectAdmins);
  const loading = useSelector(selectAdminLoading);

  useEffect(() => {
    dispatch(fetchAdmins());
  }, [dispatch]);

  const handleRoleToggle = (adminId, currentRole) => {
    dispatch(updateAdminRole({ adminId, isSuperAdmin: !currentRole }));
  };

  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4, backgroundColor: '#f5f5f5' }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Super Admin Panel
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {admins.map((admin) => (
              <ListItem key={admin.id} divider>
                <ListItemText 
                  primary={admin.email} 
                  secondary={admin.username}
                  primaryTypographyProps={{ fontWeight: 'medium' }}
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={() => handleRoleToggle(admin.id, admin.isSuperAdmin)}
                    checked={admin.isSuperAdmin}
                    color="primary"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default SuperAdminPanel;