import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAdminByEmail,
  analyzeMultiCompanyCSV,
  finalizeMultiCompanyUpload,
  fetchCompaniesFromApi,   // New
  addCompanyToFirestore,   // New
  updateCompanyInFirestore // New
} from '../services/api';

// Existing thunks
export const fetchAdminDetails = createAsyncThunk(
  'admin/fetchAdminDetails',
  async (email) => {
    const response = await fetchAdminByEmail(email);
    return response;
  }
);

export const analyzeMultiCompanyCSVThunk = createAsyncThunk(
  'admin/analyzeMultiCompanyCSV',
  async ({ parsedData, newCompanies, token }, { rejectWithValue }) => {
    try {
      console.log('[analyzeMultiCompanyCSVThunk] Sending data to backend:', {
        parsedDataLength: parsedData.length,
        newCompaniesCount: newCompanies.length
      });

      const response = await analyzeMultiCompanyCSV({ parsedData, newCompanies }, token);
      return response;
    } catch (error) {
      console.error('[analyzeMultiCompanyCSVThunk] Error:', error);
      return rejectWithValue(error.response?.data || 'An error occurred during the analysis');
    }
  }
);

export const uploadMultiCompanyCSVThunk = createAsyncThunk(
  'admin/uploadMultiCompanyCSV',
  async ({ changesByCompany, newCompanies, token }, { rejectWithValue }) => {
    try {
      console.log('[uploadMultiCompanyCSVThunk] Starting upload', {
        changesByCompanyCount: Object.keys(changesByCompany).length,
        newCompaniesCount: newCompanies.length
      });
      const response = await finalizeMultiCompanyUpload({ changesByCompany, newCompanies }, token);
      console.log('[uploadMultiCompanyCSVThunk] Upload successful', response);
      return response;
    } catch (error) {
      console.error('[uploadMultiCompanyCSVThunk] Upload failed', error);
      return rejectWithValue(error.message || 'An error occurred during the upload');
    }
  }
);

export const fetchCompanies = createAsyncThunk(
  'company/fetchCompanies',
  async (_, { rejectWithValue }) => {
    console.log('[fetchCompanies] Invoked');
    try {
      const response = await fetchCompaniesFromApi();
      console.log('[fetchCompanies] Response:', response);
      return response;
    } catch (error) {
      console.error('[fetchCompanies] Error:', error);
      return rejectWithValue('Failed to fetch companies.');
    }
  }
);


export const addCompanyThunk = createAsyncThunk(
  'company/addCompany',
  async (companyData, { rejectWithValue }) => {
    try {
      const response = await addCompanyToFirestore(companyData);
      return response; // Return newly added company data
    } catch (error) {
      return rejectWithValue('Failed to add company.');
    }
  }
);

export const updateCompanyThunk = createAsyncThunk(
  'company/updateCompany',
  async ({ companyId, ...companyData }, { rejectWithValue }) => {
    try {
      const response = await updateCompanyInFirestore(companyId, companyData);
      return response;
    } catch (error) {
      return rejectWithValue('Failed to update company.');
    }
  }
);

// Slice
const companySlice = createSlice({
  name: 'admin',
  initialState: {
    currentAdmin: null,
    companies: [],
    searchTerm: '',
    hasError: false,
    errorMessage: '',
    isAnalyzing: false,
    isUploading: false,
    isFetchingCompanies: false,
    isUpdatingCompany: false,
    analysisResults: null,
    uploadSuccess: false,
    uploadError: null,
  },
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setHasError: (state, action) => {
      state.hasError = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    resetUploadState: (state) => {
      state.isAnalyzing = false;
      state.isUploading = false;
      state.analysisResults = null;
      state.uploadSuccess = false;
      state.uploadError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Existing thunks
      .addCase(fetchAdminDetails.fulfilled, (state, action) => {
        state.currentAdmin = action.payload;
      })
      .addCase(fetchAdminDetails.rejected, (state, action) => {
        state.hasError = true;
        state.errorMessage = action.error.message || 'There was an issue loading the admin details. Please try again later.';
      })
      .addCase(analyzeMultiCompanyCSVThunk.pending, (state) => {
        state.isAnalyzing = true;
      })
      .addCase(analyzeMultiCompanyCSVThunk.fulfilled, (state, action) => {
        state.isAnalyzing = false;
        state.analysisResults = action.payload;
      })
      .addCase(analyzeMultiCompanyCSVThunk.rejected, (state, action) => {
        state.isAnalyzing = false;
        state.hasError = true;
        state.errorMessage = action.payload || 'An error occurred during the analysis';
      })
      .addCase(uploadMultiCompanyCSVThunk.pending, (state) => {
        state.isUploading = true;
      })
      .addCase(uploadMultiCompanyCSVThunk.fulfilled, (state) => {
        state.isUploading = false;
        state.uploadSuccess = true;
      })
      .addCase(uploadMultiCompanyCSVThunk.rejected, (state, action) => {
        state.isUploading = false;
        state.uploadError = action.payload || 'An error occurred during the upload';
      })

      .addCase(fetchCompanies.pending, (state) => {
        state.isFetchingCompanies = true;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.isFetchingCompanies = false;
        state.companies = action.payload;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.isFetchingCompanies = false;
        state.hasError = true;
        state.errorMessage = action.payload || 'Failed to fetch companies.';
      })
      .addCase(addCompanyThunk.pending, (state) => {
        state.isUpdatingCompany = true;
      })
      .addCase(addCompanyThunk.fulfilled, (state, action) => {
        state.isUpdatingCompany = false;
        state.companies.push(action.payload);
      })
      .addCase(addCompanyThunk.rejected, (state, action) => {
        state.isUpdatingCompany = false;
        state.hasError = true;
        state.errorMessage = action.payload || 'Failed to add company.';
      })
      .addCase(updateCompanyThunk.pending, (state) => {
        state.isUpdatingCompany = true;
      })
      .addCase(updateCompanyThunk.fulfilled, (state, action) => {
        state.isUpdatingCompany = false;
        const index = state.companies.findIndex(c => c.companyId === action.payload.companyId);
        if (index !== -1) {
          state.companies[index] = action.payload;
        }
      })
      .addCase(updateCompanyThunk.rejected, (state, action) => {
        state.isUpdatingCompany = false;
        state.hasError = true;
        state.errorMessage = action.payload || 'Failed to update company.';
      });
  }
});

export default companySlice.reducer;

export const { setSearchTerm, setHasError, setErrorMessage, resetUploadState } = companySlice.actions;
