import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import ProcessingError from '../errors/error';

const isValidFileType = (fileName) => {
  return /.csv$/i.test(fileName) || /\.xls(x)?$/i.test(fileName);
};

const isValidFileSize = (fileSize) => {
  const maxSize = 25 * 1024 * 1024; // 25MB
  return fileSize <= maxSize;
};

const isFileValid = (file) => {
  return file && isValidFileType(file.name) && isValidFileSize(file.size);
};

const parseCSV = (content) => {
  const trimmedContent = content.trim();
  const result = Papa.parse(trimmedContent, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    error: function(error, file) {
      throw new ProcessingError('File Type and format issue', error);
    }
  });
  return result.data;
};

const parseXLSX = (content) => {
  const workbook = XLSX.read(content, { type: 'binary', cellDates: true });
  const firstSheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[firstSheetName];
  return XLSX.utils.sheet_to_json(worksheet);
};

const readFileContent = (file, onSuccess, onError) => {
  const reader = new FileReader();
  const fileType = file.name.split('.').pop().toLowerCase();

  reader.onerror = () => {
    onError('Error reading the file.');
  };

  if (file.type === 'text/csv') {
    reader.onload = (event) => {
      onSuccess(parseCSV(event.target.result));
    };
    reader.readAsText(file);
  } else if (fileType === 'xlsx' || fileType === 'xls') {
    if (reader.readAsBinaryString) {
      reader.onload = (event) => {
        onSuccess(parseXLSX(event.target.result));
      };
      reader.readAsBinaryString(file);
    } else {
      reader.onload = function (evt) {
        let data = new Uint8Array(evt.target.result);
        let binaryString = '';
        data.forEach((byte) => {
          binaryString += String.fromCharCode(byte);
        });
        onSuccess(parseXLSX(binaryString));
      };
      reader.readAsArrayBuffer(file);
    }
  } else {
    onError(`Unsupported file type: ${fileType}`);
  }
};

const ensurePhoneIsString = (phone) => {
  if (phone === null || phone === undefined) return '';
  return String(phone).replace(/[^0-9]/g, '');
};

const validateData = (parsedData) => {
  return parsedData.map(record => ({
    ...record,
    phone: ensurePhoneIsString(record.phone)
  }));
};

export const processFile = (file, employees, companies, plans, companyId, onSuccess, onError) => {
  try {
    if (!isFileValid(file)) {
      throw new ProcessingError('Invalid file type or size. Max allowed size: 25MB', 'FILE_VALIDATION_ERROR');
    }

    readFileContent(
      file,
      (parsedData) => {
        const validatedData = validateData(parsedData);
        onSuccess(validatedData);
      },
      onError
    );
  } catch (error) {
    if (error instanceof ProcessingError) {
      onError(error.message);
    } else {
      onError('An unexpected error occurred.');
    }
  }
};

const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

export const validateMultiCompanyData = (parsedData, companies, plans) => {
  console.log('[csvUtil] Starting validateMultiCompanyData', { 
    dataLength: parsedData.length,
    companiesLength: companies.length,
    plansLength: plans.length
  });
  const validatedData = [];
  const errors = [];
  const newCompanies = new Map();

  parsedData.forEach((record, index) => {
    try {
      // Ensure all required fields are present
      const requiredFields = ['email', 'companyId', 'planName', 'isActive', 'address', 'dob', 'company', 'firstName', 'lastName', 'phone', 'isDependant'];
      for (const field of requiredFields) {
        if (record[field] === undefined || record[field] === null) {
          throw new ProcessingError(`Missing required field: ${field}`, 'VALIDATION_ERROR');
        }
      }

      // Validate company
      const companyId = parseInt(record.companyId);
      const company = companies.find(c => c.companyId === companyId);
      if (!company) {
        if (!newCompanies.has(companyId)) {
          console.log(`[csvUtil] New company detected: ${record.company} (ID: ${companyId})`);
          newCompanies.set(companyId, { companyId, name: record.company });
        }
      } else if (company.name.toLowerCase() !== record.company.toLowerCase()) {
        throw new ProcessingError(`Company name does not match companyId: ${record.company} (ID: ${record.companyId})`, 'VALIDATION_ERROR');
      }

      // Validate plan
      const plan = plans.find(p => p.name.toLowerCase() === record.planName.toLowerCase());
      if (!plan) {
        throw new ProcessingError(`Invalid plan: ${record.planName}`, 'VALIDATION_ERROR');
      }

      validatedData.push({
        ...record,
        companyId: companyId,
        planId: plan.planId,
        phone: ensurePhoneIsString(record.phone),
        isActive: String(record.isActive).toLowerCase() === 'true',
        isDependant: String(record.isDependant).toLowerCase() === 'true',
        dob: formatDateToYYYYMMDD(record.dob)
      });
    } catch (error) {
      console.error(`[csvUtil] Validation error in row ${index + 2}:`, error.message);
      errors.push({ row: index + 2, error: error.message });
    }
  });

  console.log('[csvUtil] validateMultiCompanyData completed', {
    validDataCount: validatedData.length,
    errorCount: errors.length,
    newCompaniesCount: newCompanies.size
  });

  console.log('[csvUtil] New companies detected:', 
    Array.from(newCompanies.values()).map(c => `${c.name} (ID: ${c.companyId})`));

  return { validatedData, errors, newCompanies: Array.from(newCompanies.values()) };
};

export const processMultiCompanyFile = (file, companies, plans, onSuccess, onError) => {
  console.log('[csvUtil] Starting processMultiCompanyFile', { fileName: file.name, fileSize: file.size });
  try {
    if (!isFileValid(file)) {
      throw new ProcessingError('Invalid file type or size. Max allowed size: 25MB', 'FILE_VALIDATION_ERROR');
    }

    readFileContent(
      file,
      (parsedData) => {
        console.log('[csvUtil] File content read successfully', { rowCount: parsedData.length });
        const result = validateMultiCompanyData(parsedData, companies, plans);
        console.log('[csvUtil] Data validation completed', {
          validDataCount: result.validatedData.length,
          errorCount: result.errors.length,
          newCompaniesCount: result.newCompanies.length
        });
        onSuccess(result);
      },
      (error) => {
        console.error('[csvUtil] Error reading file content:', error);
        onError(error);
      }
    );
  } catch (error) {
    console.error('[csvUtil] Error in processMultiCompanyFile:', error);
    if (error instanceof ProcessingError) {
      onError(error.message);
    } else {
      onError('An unexpected error occurred.');
    }
  }
};